<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="back">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('about_app') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="fixed-logo">
        <ion-img src="/assets/images/treedots_new_logo.svg" class="logo" alt="treedots" />
        <ion-text>
          <div class="mt-3 font-size-12 text-overline">v{{ version }}</div>
        </ion-text>
      </div>
      <div class="footer mb-5">
        Treedots {{ new Date().getFullYear() }} <br />{{ $t('all_rights_reserved') }}
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonText,
  IonImg,
  IonToolbar,
  IonGrid,
  IonRow,
  IonLabel,
  IonHeader
} from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import meta from '@/../package.json';

export default defineComponent({
  name: 'AboutApp',
  components: {
    IonContent,
    IonPage,
    IonText,
    IonImg,
    IonToolbar,
    IonGrid,
    IonRow,
    IonLabel,
    IonHeader
  },
  setup() {
    return {
      arrowBackOutline
    };
  },
  data() {
    return {
      version: meta.version
    };
  },
  methods: {
    back() {
      window.history.back();
    }
  }
});
</script>

<style scoped>
.text-overline {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.16667em;
}
.fixed-logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.logo {
  width: 30vw;
  padding: 10px;
  max-width: 150px;
  border-radius: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #616161 !important;
  font-weight: 600;
  font-size: 12px;
}
#row {
  height: 50px;
}
</style>
